import { LicensePlateFormat } from "../models/automaticSearch";

export const SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID = 10;
export const SEARCH_BY_VIN_METHOD_ID = 20;
export const SEARCH_BY_CH_TYPENSCHEIN_NR_ID = 40;

export const KEYWORD_LENGTH_TO_SWITCH_TO_SEARCH_BY_VIN = 13;

export const LICENSE_PLATE_FORMAT_LIST: LicensePlateFormat[] = [
  {
    countryCode: "DA", // Denmark
  },
  {
    countryCode: "SE", // Sweden
  },
  {
    countryCode: "DE", // Germany
    autoFormatRule: [
      {
        pos: 4,
        delimiter: "/",
      },
    ],
  },
  {
    countryCode: "NO", // Norway
  },
  {
    countryCode: "FI", // Finland
    autoFormatRule: [
      {
        pos: 3,
        delimiter: "-",
      },
    ],
  },
  {
    countryCode: "FR", // France
    autoFormatRule: [
      {
        pos: 2,
        delimiter: "-",
      },
      {
        pos: 5,
        delimiter: "-",
      },
    ],
  },
  {
    countryCode: "NL", // Netherlands
    // autoFormatRule: [
    //   {
    //     pos: 1,
    //     delimiter: "-",
    //   },
    //   {
    //     pos: 4,
    //     delimiter: "-",
    //   },
    // ],
  },
  {
    countryCode: "CZ", // Czech
    autoFormatRule: [
      {
        pos: 3,
        delimiter: " ",
      },
    ],
  },
  {
    countryCode: "ES", // Spanish
    autoFormatRule: [
      {
        pos: 4,
        delimiter: " ",
      },
    ],
  },
  {
    countryCode: "UK", // United Kingdom
  },
  {
    countryCode: "SK", // Slovakia
  },
  {
    countryCode: "PL", // Poland
  },
  {
    countryCode: "IE", // Ireland
    autoFormatRule: [
      {
        pos: 2,
        delimiter: "-",
      },
      {
        pos: 4,
        delimiter: "-",
      },
    ],
  },
  {
    countryCode: "IT", // Italy
    autoFormatRule: [
      {
        pos: 2,
        delimiter: " ",
      },
    ],
  },
  {
    countryCode: "PT", // Portugal
  },
  {
    countryCode: "EE", // Estonia
    autoFormatRule: [
      {
        pos: 3,
        delimiter: " ",
      },
    ],
  },
  {
    countryCode: "HU", // Hungary
    autoFormatRule: [
      {
        pos: 3,
        delimiter: "-",
      },
    ],
  },
  {
    countryCode: "HR", // Croatia
    autoFormatRule: [
      {
        pos: 2,
        delimiter: "-",
      },
      {
        pos: 5,
        delimiter: "-",
      },
    ],
  },
  {
    countryCode: "RU", // Russia
  },
  {
    countryCode: "UA", // Ukraine
    autoFormatRule: [
      {
        pos: 2,
        delimiter: " ",
      },
      {
        pos: 6,
        delimiter: " ",
      },
    ],
  },
  {
    countryCode: "IS", // Iceland
    autoFormatRule: [
      {
        pos: 2,
        delimiter: " ",
      },
    ],
  },
  {
    countryCode: "LV", // Latvia
    autoFormatRule: [
      {
        pos: 2,
        delimiter: " ",
      },
    ],
  },
  {
    countryCode: "GR", // Greece
    autoFormatRule: [
      {
        pos: 3,
        delimiter: "-",
      },
    ],
  },
  {
    countryCode: "SI", // Slovenia
    autoFormatRule: [
      {
        pos: 2,
        delimiter: "-",
      },
      {
        pos: 4,
        delimiter: "-",
      },
    ],
  },
];
