import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RequestBookingType, RequestType } from "../models/enums/requestType";
import { SimpleAccountEmployeeModel } from "../models/Account";
import Hardware from "../models/Hardware";
import { CountrySearchMethodModel } from "../models/NewRequest/CountrySearchMethod.model";
import { CarBrandModel } from "../models/support/carModelsByBrand.model";
import { OpenTimeSlot } from "../models/support/openTimeSlotFull.model";
import { TeamFavouriteModel } from "../models/contactPerson/teamFavourite.model";
import { RequestCreateSupportRequestModel } from "../models/support/request.createSupportRequest.model";
import { ServiceRequestType } from "../models/support/serviceRequestType.model";
import { SupportHours } from "../models/support/supportHours.model";
import { CarModel, Vehicle } from "../models/support/vehicle.model";
import { VehicelModel } from "../models/vehicel/vehicel.model";
import {
  createContactPersonFavouriteAsync,
  deleteContactPersonFavouriteAsync,
  getContactPersonsAsync,
} from "../services/ContactPersonsService";
import { getCreditStatusAsync } from "./creditSlide";
import { RootState } from "./store";
import {
  getAssignedSupportRequestTypesAsync,
  getCarBrandsAsync,
  getCarModelNamesByBrandAsync,
  getCarModelsByBrandAsync,
  getOpenTimeSlotsAsync,
  getSupportHoursAsync,
  getSupportRequestBookingTypes,
  getSupportRequestTypesAsync,
} from "./supportSlide";
import {
  createVciFavouriteAsync,
  deleteVciFavouriteAsync,
  getVciListAsync,
} from "./vciSlide";
import { VciFavouriteModel } from "../models/vci/vciFavourite.model";
import { TicketCategory } from "../models/NewRequest/TicketCategory.model";
import { ApplicationModel } from "../models/NewRequest/Application.model";
import {
  getApplicationsByCountryAsync,
  getHardwareFavouriteAsync,
  getTechnicianFavouriteAsync,
  getTicketCategoriesAsync,
  getUSVehicleInfoBySearchMethodAsync,
  getVehicleInfoBySearchMethodAsync,
  getVehicleInformationMappingBySearchMethodAsync,
  searchMethodsByCountryAsync,
} from "../services/VehicleInformationService";
import { AdsDisabledField } from "../ads/types/adsNewRequest.type";
import { EVehicleIdentDisabledField } from "../types/newRequest.type";
import { GetSupportRequestBookingTypesModel } from "../models/support/supportRequest.model";

export enum NewRequestStep {
  Select_Service_Type = 1,
  Vehicle_Identification = 10,
  Request_Detail = 20,
  Timeslot_Selection = 30,
  New_Request_Summary = 40,
  Request_Created_Successfully = 50,
  Select_Booking_Type = 60, // Currently only on EU
}

export interface FileModel {
  name: string;
  size: number;
  type: string;
  content: string;
}

export interface NewRequestFormValues {
  requestType: RequestType;
  searchCountry: CountrySearchMethodModel | null;
  searchCountryCode: string;
  searchCountryState: string;
  searchMethodId: number;
  searchValue: string;
  brandSelected: string;
  brandSelectedModel: Vehicle | null | undefined;
  modelSelected: string;
  modelSelectedModel: CarBrandModel | null | undefined;
  engineSelected: number;
  engineSelectedModel: CarModel | null | undefined;
  yearSelected: number;
  variantSelected: string;
  serviceTypeSelected: number;
  serviceTypeSelectedModel: ServiceRequestType | null | undefined;
  subjectSelected: number;
  subjectSelectedModel: ServiceRequestType | null | undefined;
  technicianSelected: string;
  technicianSelectedModel: SimpleAccountEmployeeModel | null | undefined;
  technicianFavourite: TeamFavouriteModel | null | undefined;
  ticketCategorySelected: number;
  ticketCategorySelectedModel: TicketCategory | null | undefined;
  hardwareSelected: number;
  hardwareSelectedModel: Hardware | null | undefined;
  hardwareFavourite: VciFavouriteModel | null | undefined;
  dateSelected: string;
  timeslotSelected: OpenTimeSlot | null;
  vin: string;
  comments: string;
  isContactAsapRequest: boolean;
  isVCIConfirmed: boolean;
  fileAttachments: FileModel[];
  disabledField: AdsDisabledField[];
  repairOrder?: string;
  mileage?: string;
  licensePlate?: string;
  selectedDiagSerial?: string; // For RDD only
  selectedVciSerial?: string; // For RDD only
  newRequestSessionId?: number | null;
  isAutoSearchByVinExecuted?: boolean;
  isCompletedStepVehicleIdentification?: boolean;
  isFormReset?: boolean;
  isAllowToPrefillVehicleData?: boolean;
  selectedBookingType?: RequestBookingType;
  vhcYourReference?: string;
}
export interface NewRequestState {
  requestUrl?: string;
  isFormDirty: boolean;
  userAvailableCredits: number;
  currentStep: NewRequestStep;
  currentStepVHC: NewRequestStep;
  requestData: RequestCreateSupportRequestModel | null;
  //isShowSearchResult: boolean,
  countrySearchMethods: CountrySearchMethodModel[];
  searchResult: VehicelModel | null;
  brands: Vehicle[];
  models: CarBrandModel[];
  engines: CarModel[];
  serviceTypes: ServiceRequestType[];
  technicians: SimpleAccountEmployeeModel[];
  ticketCategories: TicketCategory[];
  hardwares: Hardware[];
  openTimeslots: OpenTimeSlot[];
  supportHours: SupportHours[];
  formValues: NewRequestFormValues;
  isDirectSubmit: boolean;
  isIgnoreLeavingPageCheck: boolean;
  countryApplications: ApplicationModel[];
  createQueuedRequestEnabled: boolean;
  createScheduledRequestEnabled: boolean;
}

const initialState: NewRequestState = {
  isFormDirty: false,
  userAvailableCredits: 0,
  currentStep: NewRequestStep.Vehicle_Identification,
  currentStepVHC: NewRequestStep.Vehicle_Identification,
  requestData: null,
  //isShowSearchResult: false,
  countrySearchMethods: [],
  searchResult: null,
  brands: [],
  models: [],
  engines: [],
  serviceTypes: [],
  technicians: [],
  hardwares: [],
  ticketCategories: [],
  openTimeslots: [],
  supportHours: [],
  isDirectSubmit: false,
  isIgnoreLeavingPageCheck: false,
  countryApplications: [],
  createQueuedRequestEnabled: true,
  createScheduledRequestEnabled: true,
  formValues: {
    requestType: RequestType.None,
    searchCountry: null,
    searchCountryCode: "",
    searchCountryState: "",
    searchMethodId: 0,
    searchValue: "",
    brandSelected: "",
    brandSelectedModel: null,
    modelSelected: "",
    modelSelectedModel: null,
    engineSelected: 0,
    engineSelectedModel: null,
    yearSelected: 0,
    variantSelected: "",
    serviceTypeSelected: 0,
    serviceTypeSelectedModel: null,
    subjectSelected: 0,
    subjectSelectedModel: null,
    technicianSelected: "",
    technicianSelectedModel: null,
    technicianFavourite: null,
    hardwareSelected: 0,
    hardwareSelectedModel: null,
    hardwareFavourite: null,
    ticketCategorySelected: 0,
    ticketCategorySelectedModel: null,
    dateSelected: "",
    timeslotSelected: null,
    vin: "",
    comments: "",
    isContactAsapRequest: false,
    isVCIConfirmed: false,
    fileAttachments: [],
    licensePlate: "",
    mileage: "",
    repairOrder: "",
    selectedDiagSerial: "",
    selectedVciSerial: "",
    disabledField: [],
    vhcYourReference: "",
  },
};

export const parseToVehicleInfo = (data: any) => {
  let ret: VehicelModel | null = null;
  if (data) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ret = <VehicelModel>{
      imageUrl: data.Image,
      brand: data.Make,
      model: data.Model,
      vin: data.VIN,
      registration: data.LicensePlate,
      type: data.Type,
      year: data.ModelYear,
      fuelType: data.FuelType,
      registrationDate: data.RegistrationDate,
      engine: data.EngineSize,

      engineCode: data.EngineCode,
      powerKW: data.PowerKW,
      powerHP: data.PowerHP,
      transmission: data.Transmission,
      bodyStyle: data.BodyStyle,
      colorCode: data.ColorCode,
      euEmissionStage: data.EUEmissionStage,
      ownWeight: data.OwnWeight,
      totalWeight: data.TotalWeight,
      trailerWeightWithBrakes: data.TrailerWeightWithBrakes,
      trailerWeightWithoutBrakes: data.TrailerWeightWithoutBrakes,
    };
  }
  return ret;
};

export const isFormDirty = (state: NewRequestState) => {
  let isDirty = false;
  if (state.currentStep !== NewRequestStep.Vehicle_Identification)
    isDirty = true;
  if (!!state.searchResult) isDirty = true;
  if (
    !!state.formValues &&
    (!!state.formValues.brandSelected || !!state.formValues.searchValue)
  )
    isDirty = true;

  return isDirty;
};

export const newRequestSlice = createSlice({
  name: "newRequestReducer",
  initialState,
  reducers: {
    resetNewRequestState: (state) => {
      return {
        ...initialState,
        userAvailableCredits: state.userAvailableCredits,
      };
    },
    resetFormValues: (state) => {
      const newService =
        state.serviceTypes.length === 1
          ? state.serviceTypes[0]
          : initialState.formValues.serviceTypeSelectedModel;
      const newTechnician =
        state.technicians.length === 1
          ? state.technicians[0]
          : initialState.formValues.technicianSelectedModel;
      const newDvci =
        state.hardwares.length === 1 ? state.hardwares[0].serialNumber : 0;

      return {
        ...state,
        searchResult: null,
        formValues: {
          ...state.formValues,
          ...initialState.formValues,
          technicianSelected: newTechnician?.id || "",
          technicianSelectedModel: newTechnician,
          hardwareSelected: Number(newDvci) || 0,
          serviceTypeSelected: newService?.id || 0,
          serviceTypeSelectedModel: newService,
        },
      };
    },
    resetVehicleIdentForm: (state) => {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          brandSelected: initialState.formValues.brandSelected,
          brandSelectedModel: initialState.formValues.brandSelectedModel,
          modelSelected: initialState.formValues.modelSelected,
          modelSelectedModel: initialState.formValues.modelSelectedModel,
          engineSelected: initialState.formValues.engineSelected,
          engineSelectedModel: initialState.formValues.engineSelectedModel,
          yearSelected: initialState.formValues.yearSelected,
          // vin: initialState.formValues.vin,
          isAllowToPrefillVehicleData: false,
          disabledField: [],
        },
      };
    },
    resetUsVehicleIdentForm: (state) => {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          brandSelected: initialState.formValues.brandSelected,
          brandSelectedModel: initialState.formValues.brandSelectedModel,
          modelSelected: initialState.formValues.modelSelected,
          modelSelectedModel: initialState.formValues.modelSelectedModel,
          engineSelected: initialState.formValues.engineSelected,
          engineSelectedModel: initialState.formValues.engineSelectedModel,
          yearSelected: initialState.formValues.yearSelected,
          variantSelected: initialState.formValues.variantSelected,
          isAllowToPrefillVehicleData: false,
          disabledField: [],
        },
      };
    },
    resetAdsVehicleIdentForm: (state) => {
      const isDvciDisabled = state.formValues.disabledField.includes("dvci");

      return {
        ...state,
        formValues: {
          ...state.formValues,
          brandSelected: initialState.formValues.brandSelected,
          brandSelectedModel: initialState.formValues.brandSelectedModel,
          modelSelected: initialState.formValues.modelSelected,
          modelSelectedModel: initialState.formValues.modelSelectedModel,
          engineSelected: initialState.formValues.engineSelected,
          engineSelectedModel: initialState.formValues.engineSelectedModel,
          yearSelected: initialState.formValues.yearSelected,
          variantSelected: initialState.formValues.variantSelected,
          disabledField: isDvciDisabled ? ["dvci"] : [],
        },
      };
    },
    setCurrentStep: (state, action: PayloadAction<NewRequestStep>) => {
      return {
        ...state,
        currentStep: action.payload,
      };
    },
    setCurrentStepVHC: (state, action: PayloadAction<NewRequestStep>) => {
      return {
        ...state,
        currentStepVHC: action.payload,
      };
    },
    updateNewRequestState: (
      state,
      action: PayloadAction<Partial<NewRequestState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateFormValues: (
      state,
      action: PayloadAction<Partial<NewRequestFormValues>>
    ) => {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload,
        },
      };
    },
    updateDisabledFields: (
      state,
      action: PayloadAction<EVehicleIdentDisabledField>
    ) => {
      const baseDisabledFields = state.formValues.disabledField;
      const isExist = baseDisabledFields.includes(action.payload);

      return {
        ...state,
        formValues: {
          ...state.formValues,
          disabledField: isExist
            ? baseDisabledFields
            : [...baseDisabledFields, action.payload],
        },
      };
    },
    updateRequestData: (
      state,
      action: PayloadAction<RequestCreateSupportRequestModel | null>
    ) => {
      return {
        ...state,
        requestData: action.payload,
      };
    },
    updateSearchResult: (state, action: PayloadAction<VehicelModel | null>) => {
      return {
        ...state,
        searchResult: action.payload,
      };
    },
    updateIsDirectSubmit: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isDirectSubmit: action.payload,
      };
    },
    updateIsFormDirty: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isFormDirty: action.payload,
      };
    },
    updateIsIgnoreLeavingPageCheck: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isIgnoreLeavingPageCheck: action.payload,
      };
    },
    checkDirtyForm: (state) => {
      let isDirty = isFormDirty(state);
      return {
        ...state,
        isFormDirty: isDirty,
      };
    },
    updateRequestUrl: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        requestUrl: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchMethodsByCountryAsync.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            countrySearchMethods: [...(payload.dataObject as any)],
          };
        }
      })
      .addCase(
        getVehicleInformationMappingBySearchMethodAsync.fulfilled,
        (state, { payload }) => {
          if (!!payload) {
            const vehicleInfo = parseToVehicleInfo(payload);
            const ret = {
              ...state,
              searchResult: vehicleInfo,
              formValues: {
                ...state.formValues,
                vin: vehicleInfo?.vin || "",
              },
            };
            return ret;
          } else {
            return {
              ...state,
              searchResult: null,
            };
          }
        }
      )
      .addCase(
        getUSVehicleInfoBySearchMethodAsync.fulfilled,
        (state, { payload }) => {
          if (!!payload) {
            const vehicleInfo = parseToVehicleInfo(payload);
            const ret = {
              ...state,
              searchResult: vehicleInfo,
              formValues: {
                ...state.formValues,
                vin: vehicleInfo?.vin || "",
              },
            };
            return ret;
          } else {
            return {
              ...state,
              searchResult: null,
            };
          }
        }
      )
      .addCase(
        getVehicleInfoBySearchMethodAsync.fulfilled,
        (state, { payload }) => {
          if (!!payload) {
            const vehicleInfo = parseToVehicleInfo(payload);
            const ret = {
              ...state,
              searchResult: vehicleInfo,
            };
            return ret;
          } else {
            return {
              ...state,
              searchResult: null,
            };
          }
        }
      )
      .addCase(getCarBrandsAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            brands: [...(payload as any)],
          };
        }
      })
      .addCase(getCarModelNamesByBrandAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            models: [...(payload as any)],
          };
        }
      })
      .addCase(getCarModelsByBrandAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            engines: [...(payload.models as any)],
          };
        }
      })
      .addCase(getSupportRequestTypesAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          const serviceTypes = [...payload];
          if (
            state.formValues.requestType ===
              RequestType.RemoteDiagnosticRequest &&
            !!state.formValues.serviceTypeSelected
          ) {
            const selectedServiceType = serviceTypes.find(
              (x) => x.id === state.formValues.serviceTypeSelected
            );
            return {
              ...state,
              serviceTypes: serviceTypes,
              formValues: {
                ...state.formValues,
                serviceTypeSelectedModel: selectedServiceType,
              },
            };
          } else if (
            state.formValues.requestType === RequestType.TechnicalRequest &&
            !!state.formValues.subjectSelected
          ) {
            const selectedServiceType = serviceTypes.find(
              (x) => x.id === state.formValues.subjectSelected
            );
            return {
              ...state,
              serviceTypes: serviceTypes,
              formValues: {
                ...state.formValues,
                subjectSelectedModel: selectedServiceType,
              },
            };
          }

          return {
            ...state,
            serviceTypes: serviceTypes,
          };
        }
      })
      .addCase(
        getAssignedSupportRequestTypesAsync.fulfilled,
        (state, { payload }) => {
          if (payload) {
            const serviceTypes = [...payload];
            const selectedServiceType = serviceTypes.find(
              (x) => x.id === state.formValues.serviceTypeSelected
            );
            return {
              ...state,
              serviceTypes: serviceTypes,
              formValues: {
                ...state.formValues,
                serviceTypeSelectedModel: selectedServiceType,
              },
            };
          }
        }
      )
      .addCase(getContactPersonsAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            technicians: [...(payload as any)],
          };
        }
      })
      .addCase(getVciListAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          const hardwares = [...payload];
          if (!!state.formValues?.hardwareSelected) {
            const hardware = hardwares.find(
              (x) => x.id === state.formValues.hardwareSelected
            );
            return {
              ...state,
              hardwares: hardwares,
              formValues: {
                ...state.formValues,
                hardwareSelectedModel: hardware,
              },
            };
          }

          return {
            ...state,
            hardwares: hardwares,
          };
        }
      })
      .addCase(deleteVciFavouriteAsync.fulfilled, (state, { payload }) => {
        if (!!payload?.dataObject) {
          return {
            ...state,
            formValues: {
              ...state.formValues,
              hardwareFavourite: null,
            },
          };
        }
      })
      .addCase(createVciFavouriteAsync.fulfilled, (state, { payload }) => {
        if (!!payload?.dataObject) {
          if (!!payload?.dataObject) {
            return {
              ...state,
              formValues: {
                ...state.formValues,
                hardwareFavourite: payload.dataObject,
              },
            };
          }
        }
      })
      .addCase(getCreditStatusAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            userAvailableCredits: payload?.dataObject?.credits || 0,
          };
        }
      })
      .addCase(getOpenTimeSlotsAsync.fulfilled, (state, { payload }) => {
        if (
          !!payload &&
          payload.agents &&
          payload.agents.length > 0 &&
          !!payload.agents[0].openTimeSlots
        ) {
          let openTimeSlots: OpenTimeSlot[] = [];

          payload.agents[0].openTimeSlots.forEach((timeSlot) => {
            openTimeSlots.push({
              startTime: timeSlot.startTime,
              endTime: timeSlot.endTime,
            });
          });

          return {
            ...state,
            openTimeslots: openTimeSlots.map((x) => {
              return {
                ...x,
                startTime: moment(x.startTime).format("YYYY-MM-DD HH:mm:ss"),
                endTime: moment(x.endTime).format("YYYY-MM-DD HH:mm:ss"),
              };
            }),
          };
        } else {
          return {
            ...state,
            openTimeslots: [],
          };
        }
      })
      .addCase(getSupportHoursAsync.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            supportHours: payload,
          };
        }

        return {
          ...state,
          supportHours: [],
        };
      })
      .addCase(getTechnicianFavouriteAsync.fulfilled, (state, { payload }) => {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            technicianFavourite: payload?.dataObject as TeamFavouriteModel,
          },
        };
      })
      .addCase(
        deleteContactPersonFavouriteAsync.fulfilled,
        (state, { payload }) => {
          if (!!payload?.dataObject) {
            return {
              ...state,
              formValues: {
                ...state.formValues,
                technicianFavourite: null,
              },
            };
          }
        }
      )
      .addCase(
        createContactPersonFavouriteAsync.fulfilled,
        (state, { payload }) => {
          if (!!payload?.dataObject) {
            return {
              ...state,
              formValues: {
                ...state.formValues,
                technicianFavourite: payload.dataObject,
              },
            };
          }
        }
      )
      .addCase(getHardwareFavouriteAsync.fulfilled, (state, { payload }) => {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            hardwareFavourite: payload?.dataObject as VciFavouriteModel,
          },
        };
      })
      .addCase(getTicketCategoriesAsync.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject
        ) {
          return {
            ...state,
            ticketCategories: [...(payload.dataObject as any)],
          };
        }
      })
      .addCase(
        getApplicationsByCountryAsync.fulfilled,
        (state, { payload }) => {
          if (
            payload != null &&
            payload.httpStatusCode === 200 &&
            payload.dataObject
          ) {
            return {
              ...state,
              countryApplications: [...payload.dataObject],
            };
          }
        }
      )
      .addCase(
        getSupportRequestBookingTypes.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            createQueuedRequestEnabled:
              payload?.dataObject?.createQueuedRequestEnabled || false,
            createScheduledRequestEnabled:
              payload?.dataObject?.createScheduledRequestEnabled || false,
          };
        }
      );
  },
});
export const selectIsFormDirty = (rootState: RootState) =>
  rootState.newRequest.isFormDirty;
export const selectRequestData = (rootState: RootState) =>
  rootState.newRequest.requestData;
export const selectCurrentStep = (rootState: RootState) =>
  rootState.newRequest.currentStep;
export const selectCurrentStepVHC = (rootState: RootState) =>
  rootState.newRequest.currentStepVHC;
export const selectCountrySearchMethods = (rootState: RootState) =>
  rootState.newRequest.countrySearchMethods;
export const selectFormValues = (rootState: RootState) =>
  rootState.newRequest.formValues;
export const selectSelectedRequestType = (rootState: RootState) =>
  rootState.newRequest.formValues?.requestType;
export const selectBrands = (rootState: RootState) =>
  rootState.newRequest.brands;
export const selectModels = (rootState: RootState) =>
  rootState.newRequest.models;
export const selectEngines = (rootState: RootState) =>
  rootState.newRequest.engines;
export const selectTicketCategories = (rootState: RootState) =>
  rootState.newRequest.ticketCategories;
export const selectServiceTypes = (rootState: RootState) =>
  rootState.newRequest.serviceTypes;
export const selectTechnicians = (rootState: RootState) =>
  rootState.newRequest.technicians;
export const selectHardwares = (rootState: RootState) =>
  rootState.newRequest.hardwares;
export const selectOpenTimeslots = (rootState: RootState) =>
  rootState.newRequest.openTimeslots;
export const selectSupportHours = (rootState: RootState) =>
  rootState.newRequest.supportHours;
export const selectSearchResult = (rootState: RootState) =>
  rootState.newRequest.searchResult;
export const selectUserAvailableCredits = (rootState: RootState) =>
  rootState.newRequest.userAvailableCredits;
export const selectIsDirectSubmit = (rootState: RootState) =>
  rootState.newRequest.isDirectSubmit;
export const selectCountryApplications = (rootState: RootState) =>
  rootState.newRequest.countryApplications;
export const selectDisabledFields = (rootState: RootState) =>
  rootState.newRequest.formValues.disabledField;
export const selectSupportRequestBookingTypes = (
  rootState: RootState
): GetSupportRequestBookingTypesModel => ({
  createQueuedRequestEnabled: rootState.newRequest.createQueuedRequestEnabled,
  createScheduledRequestEnabled:
    rootState.newRequest.createScheduledRequestEnabled,
});
export const selectSelectedBookingType = (rootState: RootState) =>
  rootState.newRequest.formValues.selectedBookingType;
export const selectRequestUrl = (rootState: RootState) =>
  rootState.newRequest.requestUrl;

export const {
  resetNewRequestState,
  resetFormValues,
  resetVehicleIdentForm,
  resetUsVehicleIdentForm,
  resetAdsVehicleIdentForm,
  setCurrentStep,
  setCurrentStepVHC,
  updateNewRequestState,
  updateRequestData /*setIsShowSearchResult,*/,
  /*setSelectedRequestType,*/ checkDirtyForm,
  updateFormValues,
  updateDisabledFields,
  updateSearchResult,
  updateIsDirectSubmit,
  updateIsFormDirty,
  updateIsIgnoreLeavingPageCheck,
  updateRequestUrl,
} = newRequestSlice.actions;
export default newRequestSlice.reducer;
